<template>
  <d-remote-monitoring-admin-tasks />
</template>

<script>

export default {
  name: 'RemoteMonitoringAdminTasks',
};
</script>
